import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LayoutProvider, UserProvider, SnackbarProvider } from "context";
import { Provider as RollbarProvider } from "@rollbar/react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "shared-components";

if (process.env.REACT_APP_APP_ENV === "mock") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

function isRollBarEnabled() {
  const env = (process.env.REACT_APP_APP_ENV ?? "").toLowerCase();

  return env === "production" || env === "staging";
}

const rollbarConfig = {
  enabled: isRollBarEnabled(),
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_APP_ENV,
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

root.render(
  <RollbarProvider config={rollbarConfig}>
    <ThemeProvider theme={theme}>
      <LayoutProvider>
        <SnackbarProvider>
          <UserProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </UserProvider>
        </SnackbarProvider>
      </LayoutProvider>
    </ThemeProvider>
  </RollbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
