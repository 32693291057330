import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";
import useComponentDidMount from "hooks/useComponentDidMount";

import axios from "axios";

import { LayoutContext } from "context";
import { UserContext } from "context";
import noop from "lodash/noop";
import includeCustomerIo from "utils/customerIo";
import { isAdmin } from "utils/authorize";
import { Header } from "shared-components";
import algoliasearch from "algoliasearch/lite";
import { UserType } from "../../pages/users/sign-up/types";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID ?? "",
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC ?? "",
);

export default function Layout() {
  const { userData, setUserData, setPavedFee } = useContext(UserContext);
  const { isDataLoading } = useContext(LayoutContext);

  const { userType, has_marketplace_access } = userData;

  const currentSearchClient =
    userType === UserType.ADVERTISER && has_marketplace_access
      ? searchClient
      : null;

  const getPavedFee = () => {
    axios
      .get("/api/v1/publisher/sites/fees")
      .then((response) => {
        setPavedFee(response.data);
      })
      .catch(noop);
  };

  useComponentDidMount(() => {
    if (!!userData.firstName) {
      if (userData.userType === "publisher") {
        getPavedFee();
      }

      return;
    }

    axios
      .get("/api/v1/users/me")
      .then((response) => {
        const {
          first_name,
          last_name,
          company_name,
          user_type,
          has_booker_access,
          has_ad_network_access,
          created_at,
          has_marketplace_access,
          uuid,
          email,
          menu,
          ro_id,
        } = response.data;

        includeCustomerIo(email, created_at);

        if (user_type === "publisher") {
          getPavedFee();
        }
        setUserData({
          firstName: first_name,
          lastName: last_name,
          companyName: company_name,
          userType: user_type,
          createdAt: new Date(created_at),
          isAdmin: isAdmin(),
          has_booker_access,
          has_ad_network_access,
          has_marketplace_access,
          userMenu: menu,
          uuid,
          ro_id,
          email,
        });
      })
      .catch(noop);
  });

  return (
    <>
      <Header
        userData={userData}
        isDataLoading={isDataLoading}
        isEmbedded={false}
        searchClient={currentSearchClient}
        indexName={INDEX_NAME}
      />
      <Container fixed={true} sx={{ pt: 2, pb: 3, px: 1.5 }}>
        <Outlet />
      </Container>
    </>
  );
}

export { LayoutContext };
