import React, { createContext, useState, ReactNode } from "react";
import noop from "lodash/noop";
import { IPavedFee } from "types";
import { User } from "shared-components";

interface IUserContext {
  userData: User;
  pavedFee: IPavedFee;
  setUserData: (value: User) => void;
  setPavedFee: (fee: IPavedFee) => void;
}

const DEFAULT_STATE: IUserContext = {
  userData: {
    firstName: "",
    lastName: "",
    companyName: "",
    createdAt: new Date(),
    isAdmin: false,
    has_booker_access: false,
    has_ad_network_access: false,
    has_marketplace_access: false,
    userMenu: [],
    uuid: "",
    ro_id: null,
  },
  pavedFee: {
    dedicated_email: 0,
    sponsored_email: 0,
    sponsored_post: 0,
  },
  setUserData: noop,
  setPavedFee: noop,
};

export const UserContext = createContext(DEFAULT_STATE);

export interface IUserProviderProps {
  children?: ReactNode;
}

export const UserProvider = ({ children }: IUserProviderProps) => {
  const [userData, setUserData] = useState(DEFAULT_STATE.userData);
  const [pavedFee, setPavedFee] = useState(DEFAULT_STATE.pavedFee);

  return (
    <UserContext.Provider
      value={{
        userData,
        pavedFee,
        setUserData,
        setPavedFee,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
